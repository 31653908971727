<template>
    <div class="floating-special-wrapper">
        <section class="floating-special">
            <h3 class="floating-special_title" v-html="$t('contracts.floating.spec-title')"></h3>
            <p class="floating-special_subtitle" v-html="$t('contracts.floating.spec-subtitle')"></p>
            <img class="floating-special_scene" :src="require(`@/assets/img/scenes/contracts-7.svg`)" alt="">
            <div class="floating-special-background">
                <img class="floating-special-background_network-one" :src="require(`@/assets/img/scenes/development-installation-network.svg`)" alt="">
                <img class="floating-special-background_network-two" :src="require(`@/assets/img/scenes/development-installation-network.svg`)" alt="">
            </div>
        </section>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="sass" scoped>
@import '@/pages/apartials/floatingElementSpecial.sass'

.floating-special-wrapper
    background: #FFF
    padding-top: 20px
    position: relative
    &:before
        content: ''
        position: absolute 
        top: 0
        left: 0
        width: 100% 
        height: 100%
        background: #F4F7FF
        border-radius: 50% 50% 0 0/ 100% 100% 0 0
        transform: scaleX(4.4)
        z-index: 0
</style>