<template>
    <section class="audit-section">
        <div class="nice-border"></div>
        <h3 class="audit-section_headtext" v-html="$t('contracts.audit.title')" />
        <img class="audit-section_img" :src="require(`@/assets/img/contracts/contracts-doc.svg`)" alt="" >
        <p class="audit-section_paragraph">{{$t('contracts.audit.paragraph')}}</p>
        <img class="audit-section_img-standards" :src="require(`@/assets/img/contracts/contracts-standards.svg`)" alt="" >
        <img class="audit-section_img-standards--mobile" :src="require(`@/assets/img/contracts/contracts-standards-mobile.svg`)" alt="" >
    </section>
</template>

<script>
export default {
    
}
</script>

<style lang="sass" scoped>
@import '@/pages/contracts/sections/audit.sass'
.nice-border
    position: relative
    padding-bottom: 90px
    width: 100%
    &:before
        content: ''
        position: absolute 
        left: 0
        bottom: 100%
        width: 100% 
        height: 100%
        background: #F4F7FF
        border-radius: 50% 50% 0 0 / 100% 100% 0 0 
        transform: scaleX(1.2)
        z-index: 0
    @media screen and (max-width: 320px), (min-device-width: 100px) and (max-device-width: 320px)
        padding-bottom: 40px
</style>