<template>
    <section class="contracts-page">
        <section-head title="sectionhead.title-contracts" subtitle="sectionhead.subtitle-contracts" />
        <section-options class="special-margin" style="margin-top: 100px" :icons="options.icons" :description="options.description" />
        <section-audit style="margin-top: 120px" />

        <section-float 
            title="contracts.floating.headtext" 
            isWhiteBorderTop="yes" 
            class="special-float-tablet-container"
        >
            <div class="img-mobile">
                <img :src="require(`@/assets/img/scenes/contracts-5.svg`)" alt=""/>
            </div>
            <element-float 
                 v-for="(element, index) in 6" 
                :key="index"
                :title="`contracts.floating.title-${index}`"
                :listFirst="`contracts.floating.el-${index}`"
                :icon="`contracts-${index+1}`"
                :numberMark="index+1"
                :checkmarkOff="true"
                class="special-float-tablet"
            />
            <div class="border-vl">
                <svg width="51" height="3078" viewBox="0 0 51 3078" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="25.002" cy="23" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="23" r="4" fill="#4071F4"/>
                    <circle cx="25.002" cy="520" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="520" r="4" fill="#4071F4"/>
                    <circle cx="25.002" cy="1017" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="1017" r="4" fill="#4071F4"/>
                    <circle cx="25.002" cy="2014" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="2014" r="4" fill="#4071F4"/>
                    <circle cx="25.002" cy="1514" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="1514" r="4" fill="#4071F4"/>
                    <circle cx="25.002" cy="2514" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                    <circle cx="25.002" cy="2514" r="4" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="61" width="2" height="421" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="558" width="2" height="421" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="1055" width="2" height="421" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="1552" width="2" height="421" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="2052" width="2" height="421" fill="#4071F4"/>
                    <rect opacity="0.5" x="24.002" y="2552" width="2" height="460" fill="#4071F4"/>
                    <rect x="0.00195312" y="3028" width="50" height="50" rx="4" fill="#4071F4"/>
                    <rect x="0.00195312" y="3028" width="50" height="50" rx="4" fill="url(#paint0_linear)"/>
                    <rect x="0.00195312" y="3028" width="50" height="50" rx="4" fill="url(#paint1_linear)"/>
                    <path d="M18.982 3042.4H32.394V3045.06C31.3673 3046.22 30.434 3047.42 29.594 3048.67C28.754 3049.9 28.0166 3051.22 27.382 3052.62C26.766 3054 26.262 3055.47 25.87 3057.02C25.478 3058.57 25.2166 3060.23 25.086 3062H21.586C21.698 3060.45 21.9593 3058.92 22.37 3057.41C22.7993 3055.9 23.3406 3054.43 23.994 3053.01C24.666 3051.59 25.4406 3050.23 26.318 3048.92C27.1953 3047.62 28.138 3046.39 29.146 3045.26H18.982V3042.4Z" fill="white"/>
                    <defs>
                    <linearGradient id="paint0_linear" x1="0.00198923" y1="3054.19" x2="106.252" y2="2986.04" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#ED6B6B"/>
                    <stop offset="1" stop-color="#FFA275"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="25.9784" y1="3029.08" x2="22.5732" y2="3068.42" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#73A9FF"/>
                    <stop offset="1" stop-color="#415DF8"/>
                    </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="border-number-container">
                <div class="border-number-wrapper">
                    <div class="border-number">
                        <div class="border-number-itself">7</div>
                    </div>
                </div>
            </div>

        </section-float>

        
        <element-float-special />

        <section-footer style="background: #F4F7FF" paragraph="sectionfooter.paragraph-contracts" />
    </section>
</template>

<script>
import sectionHead from '@/pages/asections/sectionHead.vue';
import sectionFooter from '@/pages/asections/sectionFooter.vue';
import optionsSection from '@/pages/asections/optionsSection.vue';
import audit from '@/pages/contracts/sections/audit.vue';
import floatingSection from '@/pages/asections/floatingSection.vue';
import floatingElement from '@/pages/apartials/floatingElement.vue';
import floatingElementSpecial from '@/pages/apartials/floatingElementSpecial.vue';
import { getAlternatesForCurrentLanguage, getCanonicals } from '@/helpers/locale-helper'

export default {
    metaInfo() {
        return {
            title: this.$t('meta_title-contracts'),
            keywords: this.$t('meta_keywords'),
            description: this.$t('meta_contracts-description'),
            link: getAlternatesForCurrentLanguage().concat(getCanonicals())
        }
    },
    data: () => ({
        options: {
            description: {
                headtext: 'contracts.desc.headtext',
            },

            icons: [{
                title: 'icontitle.token',
                name: 'options-token'
            },
            {
                title: 'icontitle.currency',
                name: 'options-currency'
            },
            {
                title: 'icontitle.swap',
                name: 'options-swap'
            },
            {
                title: 'icontitle.defi',
                name: 'options-defi'
            },
            {
                title: 'icontitle.wallets',
                name: 'options-wallets'
            }],

            floatingBlocks: [{
                title: 'floating.development.title-1',
                icon: 'contracts-1',
                listFirst: 'floating.development.el-1.it-1'
            },
            {
                title: 'floating.development.title-2',
                icon: 'development-floating-crypto',
                listFirst: 'floating.development.el-2.it-1'
            },
            {
                title: 'floating.development.title-3',
                icon: 'development-floating-backups',
                listFirst: 'floating.development.el-3.it-1'
            },
            {
                title: 'floating.development.title-1',
                icon: 'development-floating-legal',
                listFirst: 'floating.development.el-1.it-1'
            },
            {
                title: 'floating.development.title-2',
                icon: 'development-floating-crypto',
                listFirst: 'floating.development.el-2.it-1'
            },
            {
                title: 'floating.development.title-3',
                icon: 'development-floating-backups',
                listFirst: 'floating.development.el-3.it-1'
            }]
        }
    }),
    components: {
        'section-head': sectionHead,
        'section-footer': sectionFooter,
        'section-options': optionsSection,
        'section-audit': audit,
        'section-float': floatingSection,
        'element-float': floatingElement,
        'element-float-special': floatingElementSpecial
    }
}

</script>

<style lang="sass" scoped>
@import '@/pages/contracts/contractsPage.sass'

.contracts-page 
    color: $color--dark

.img-mobile
    display: none 
    @media screen and (min-width: 100px) and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
        display: flex
        justify-content: center
        align-items: center
            

.special-float-tablet-container
    padding-bottom: 65px 
    ::v-deep .floating-section-elements
        @media screen and (min-width: 540px) and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
            width: 100%
            padding: 0 42px
        @media screen and (min-width: 416px) and (max-width: 540px), (min-device-width: 416px) and (max-device-width: 416px)
            padding: 0 18px
            

.special-float-tablet
    padding: 40px 0px 0px 0px
    margin: 0
    diplay: flex
    flex-direction: row-reverse

    ::v-deep 
        .floating-element_description
            margin: 0 0 0 50px
            padding: 0 0 0 100px
            @media screen and (min-width: 416px) and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
                padding: 0
                margin: 0 0 0 50px
            @media screen and (min-width: 100px) and (max-width: 416px), (min-device-width: 100px) and (max-device-width: 416px)
                margin: 0
                padding: 0

    &:nth-child(2n-1)
        @media screen and (min-width: 416px) and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
        ::v-deep 
            .floating-element_description
                padding-right: 0px  
                &_item_def 
                    padding-right: 0px


    &:nth-child(2n)   
        flex-direction: row

        ::v-deep 
            .floating-element_description
                padding-right: 0px  
                &_item_def 
                    padding-right: 10px
        
        ::v-deep
            .floating-element_description
                margin: 0 50px 0 0
                padding: 0 70px 0 0
                @media screen and (min-width: 416px) and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
                    padding: 0
                    margin: 0
                @media screen and (min-width: 100px) and (max-width: 416px), (min-device-width: 100px) and (max-device-width: 416px)
                    margin: 0
                    padding: 0


    @media screen and (min-width: 416px) and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
        display: flex
        flex-direction: row-reverse
        height: fit-content
        align-items: center 
        justift-content: center

        &:nth-child(2n)
            @media screen and (min-width: 416px) and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
                display: flex
                flex-direction: row

                ::v-deep .floating-element_icon
                    display: flex 
                    align-items: center 
                    justify-content: center
                    height: fit-content

                ::v-deep .floating-element_description
                    width: 50%
                    margin-right: 49px
                    @media screen and (min-width: 642px) and (max-width: 768px), (min-device-width: 642px) and (max-device-width: 768px)
                        width: 95%
                        margin-right: 30px
                    @media screen and (min-width: 416px) and (max-width: 642px), (min-device-width: 416px) and (max-device-width: 642px)
                        width: 100%
                        margin-right: 0
                    @media screen and (min-width: 100px) and (max-width: 416px), (min-device-width: 100px) and (max-device-width: 416px)
                        width: 100%
                        margin-right: 49px
        
                        
    @media screen and (min-width: 416px) and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
        ::v-deep .floating-element_icon
            display: flex 
            align-items: center 
            justify-content: center

        ::v-deep .floating-element_description-title 
            justify-content: flex-start
        ::v-deep .floating-element_description-title_text 
            text-align: left
            font-size: 24px
            line-height: 32px

    @media screen and (min-width: 100px) and (max-width: 642px), (min-device-width: 100px) and (max-device-width: 642px)
        margin: 0
        ::v-deep .floating-element_description-title 
            flex-direction: column
            align-items: center 
            justify-content: center
            &_text 
                font-size: 18px 
                line-height: 22px
                margin: 12px 0 0 0 
            &_number 
                width: 40px
        ::v-deep .floating-element_icon 
            display: none

        &:nth-child(2n)
            ::v-deep .floating-element_icon
                display: none

        ::v-deep .floating-element_description
            margin: 0




.special-margin
    ::v-deep 
        .options-section-icons_wrapper
                flex: 1 1 33.3%
                &:last-child
                    margin-right: 140px
                    @media screen and (max-width: 860px), (min-device-width: 416px) and (max-device-width: 800px)
                        margin-right: 0

                &:nth-child(4)
                    margin-left: 140px
                    @media screen and (max-width: 860px), (min-device-width: 416px) and (max-device-width: 800px)
                        margin-left: 0


.border-vl
    position: absolute
    top: 430px
    left: 49%
    height: 120px
    z-index: 999
    @media screen and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
        display: none

    border-radius: 90% 90% 0 0

.border-number
    position: absolute
    height: 120px
    z-index: 999
    display: none
    width: 50px 
    height: 50px
    color: #fff
    border-radius: 4px
    align-items: center 
    justify-content: center
    background: linear-gradient(184.95deg, #73A9FF 5.81%, #415DF8 78.76%), linear-gradient(57.32deg, #ED6B6B 18.61%, #FFA275 201.33%), #4071F4
    @media screen and (max-width: 768px), (min-device-width: 416px) and (max-device-width: 768px)
        display: flex
        left: 40%
        top: 40px
        left: -25px
    @media screen and (max-width: 416px), (min-device-width: 100px) and (max-device-width: 416px)
        top: 70px
    &-container
        display: flex 
        align-items: center 
        justify-content: center 
    &-itself 
        font-size: 28px 
        color: #fff
    &-wrapper 
        position: relative
        
.contracts-page
    .options-section-icons_wrapper
        &:nth-child(4)
            margin-left: 10%
            @media screen and (max-width: 600px), (min-device-width: 416px) and (max-device-width: 600px)
                margin-left: 0

        &:last-child
            margin-right: 10%
            @media screen and (max-width: 600px), (min-device-width: 416px) and (max-device-width: 600px)
                margin-right: 0
</style>

